/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('/static/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/static/fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('/static/fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/roboto-slab-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
       url('/static/fonts/roboto-slab-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/roboto-slab-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/roboto-slab-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/roboto-slab-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/roboto-slab-v7-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/roboto-slab-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
       url('/static/fonts/roboto-slab-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/roboto-slab-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/roboto-slab-v7-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/roboto-slab-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/roboto-slab-v7-latin-700.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}
